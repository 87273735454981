import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function InfoKey() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Retrieve deviceInfo from sessionStorage
  const storedDeviceInfo = sessionStorage.getItem('deviceInfo');
  const deviceInfo = JSON.parse(storedDeviceInfo);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleButtonClick = () => {
      navigate('/');
    };

    // Set the Main Button
    tg.MainButton.setText("Вернуться");
    tg.MainButton.show();
    tg.MainButton.onClick(handleButtonClick);

    // Show the Back Button
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/');
    });

    // Cleanup to prevent memory leaks
    return () => {
      tg.MainButton.offClick(handleButtonClick);
      tg.MainButton.hide();
      tg.BackButton.hide();
    };
  }, [navigate]);

  // Handle copying the appropriate link based on the device type
  const handleCopyKey = () => {
    const { link } = deviceInfo;  // Get the appropriate link from deviceInfo

    if (navigator.clipboard && link) {
      navigator.clipboard.writeText(link)
        .then(() => {
          handleButtonTextChange('Ссылка скопирована');
        })
        .catch(err => {
          console.error('Could not copy text:', err);
          fallbackCopyText(link);  // Fallback in case of error
        });
    } else {
      alert('Clipboard API not available or link is missing');
    }
  };

  // Fallback function for copying text
  const fallbackCopyText = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      handleButtonTextChange('ключ скопирован');
    } catch (err) {
      console.error('Fallback: Could not copy text:', err);
    }
    document.body.removeChild(textArea);
  };

  // Change button text temporarily after copying
  const handleButtonTextChange = (message) => {
    const copyButton = document.querySelector('.copy-button-text');
    copyButton.textContent = message;
    setTimeout(() => {
      copyButton.textContent = 'ваш ключ';
    }, 2000);
  };

  // Render instructions based on the device type
  const renderInstructions = () => {
    const { deviceType, link, images } = deviceInfo;

    if (deviceType === 'iOS') {
      return (
        <div>
          <h2 style={{fontSize: '20px', marginTop: '20px', marginBottom: '10px'}}>Инструкция для iPhone</h2>
          <div className='text' style={{marginBottom: '10px'}}>
            1. Скачайте приложение <a style={{textDecoration: 'none'}} href={link}>V2RayTun</a>;<br />
            2. Скопируйте <span className='copy-button-text' onClick={handleCopyKey} style={{color: '#0088cc', cursor: 'pointer'}}>ваш ключ</span>;<br />
            3. Вставьте ключ в приложении, как показано на картинке:
          </div>
          {images && images.map((img, index) => (
          <img key={index} style={{width: '100%', marginBottom: '10px'}} src={img} alt={`Instruction ${index + 1}`} />
          ))}
        </div>
      );
    } else if (deviceType === 'Android') {
      return (
        <div>
          <h2 style={{fontSize: '20px', marginTop: '20px', marginBottom: '10px'}}>Инструкция для Android</h2>
          <div className='text' style={{marginBottom: '20px'}}>
            1. Скачайте приложение <a style={{textDecoration: 'none'}} href={link}>v2rayNG</a>;<br />
            2. Скопируйте <span className='copy-button-text' onClick={handleCopyKey} style={{color: '#0088cc', cursor: 'pointer'}}>ваш ключ</span>;<br />
            3. Вставьте ключ в приложении, как показано на картинках:
          </div>
          {images && images.map((img, index) => (
          <img key={index} style={{width: '100%', marginBottom: '10px'}} src={img} alt={`Instruction ${index + 1}`} />
          ))}
        </div>
      );
    } else if (deviceType === 'Mac') {
      return (
        <div>
          <h2 style={{fontSize: '20px', marginTop: '20px', marginBottom: '10px'}}>Инструкция для Mac</h2>
          <div className='text' style={{marginBottom: '20px'}}>
            1. Скачайте приложение <a style={{textDecoration: 'none'}} href={link}>FoxRay</a>;<br />
            2. Скопируйте <span className='copy-button-text' onClick={handleCopyKey} style={{color: '#0088cc', cursor: 'pointer'}}>ваш ключ</span>;<br />
            3. Вставьте ключ в приложении, как показано на картинке:
          </div>
          {images && images.map((img, index) => (
          <img key={index} style={{width: '100%', marginBottom: '10px'}} src={img} alt={`Instruction ${index + 1}`} />
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <h2>Инструкция</h2>
          <div className='text' style={{marginBottom: '20px'}}>
            1. Скачайте приложение <a style={{textDecoration: 'none'}} href={link}>Streisand или v2rayNG</a>;<br />
            2. Используйте ссылку для подключения через VLESS
          </div>
        </div>
      );
    }
  };

  return (
    <div className='wrapper'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h1 className='text' style={{ fontSize: '22px', textAlign: 'left', marginBottom: '20px' }}>
            Как пользоваться ключами
          </h1>
          {renderInstructions()}
        </>
      )}
    </div>
  );
}

export default InfoKey;
